.name-header {
  font-weight: bold;
  font-size: 24px;
}

.name-subheader {
  font-weight: bold;
  font-size: 20px;
}

.name-text {
  font-size: 16px;
}

.name-label {
  text-align: center;
}