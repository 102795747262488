.portfolio-header {
  font-weight: bold;
  font-size: 24px;
}

.portfolio-subheader {
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
}

.portfolio-text {
  font-size: 16px;
}

.portfolio-label {
  text-align: center;
}