.hobbies-header {
  font-weight: bold;
  font-size: 24px;
}

.hobbies-subheader {
  font-weight: bold;
  font-size: 20px;
}

.hobbies-text {
  font-size: 16px;
}

.hobbies-image {
  display: flex;
  justify-content: center;
  align-items: center;
}