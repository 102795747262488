.skills-header {
  font-weight: bold;
  font-size: 24px;
}

.skills-subheader {
  font-weight: bold;
  font-size: 20px;
}

.skills-text {
  font-size: 16px;
}

.skills-image {
  display: flex;
  justify-content: center;
  align-items: center;
}