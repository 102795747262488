.contact-info-header {
  font-weight: bold;
  font-size: 24px;
}

.contact-info-subheader {
  font-weight: bold;
  font-size: 20px;
}

.contact-info-text {
  font-size: 16px;
}

.contact-info-label {
  text-align: center;
}