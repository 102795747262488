.references-header {
  font-weight: bold;
  font-size: 24px;
}

.references-subheader {
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
}

.references-text {
  font-size: 16px;
  font-style: italic;
}

.references-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.references-label {
  text-align: center;
}