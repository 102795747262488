.home-header {
  font-weight: bold;
  font-size: 24px;
}

.home-subheader {
  font-weight: bold;
  font-size: 20px;
}

.home-text {
  font-size: 16px;
}

.home-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-portrate {
  height: 250px;
  width: 250px;
}

.home-label {
  text-align: center;
}