.profile-header {
  font-weight: bold;
  font-size: 24px;
}

.profile-subheader {
  font-weight: bold;
  font-size: 20px;
}

.profile-text {
  font-size: 16px;
}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
